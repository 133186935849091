import styled from 'styled-components'
import { breakpoints } from '@/styles/media'

export const CMSFullBleed = styled.div<{
  $background_color?: string | null
}>`
  background-color: ${({ $background_color }) => $background_color};
  width: 100%;

  @media ${breakpoints.large} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`
